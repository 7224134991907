import React from "react";
import StyledButton from "../StyledButton";
import { useIntl } from "react-intl";
import { useSwiper } from "swiper/react";
import {
  BottomContainer,
  CenterContainer,
  PageTitle,
  Subtitle,
} from "../../styles/components";
import { useGuestsContext } from "../../context";
import GuestsFood from "../GuestsFood";
import { SpecialFood } from "../../types";

export type FoodOption = { label: string; value: SpecialFood };

export default function FoodSlide() {
  const { guestsRequest, setGuestsRequest } = useGuestsContext();
  const { formatMessage } = useIntl();
  const swiper = useSwiper();

  const onFoodChange = (
    key: "adults" | "kids" | "babies",
    guestName: string,
    selectedOption: any
  ) => {
    const newGuestsInfo = { ...guestsRequest };
    let newGuestsInfoList = newGuestsInfo[key];
    if (newGuestsInfoList !== undefined) {
      newGuestsInfoList = newGuestsInfoList.map((guest) => {
        if (guest.name === guestName) {
          guest.foodType = selectedOption;
        }

        return guest;
      });
    }
    debugger;
    setGuestsRequest(newGuestsInfo);
  };

  return (
    <CenterContainer>
      <PageTitle>{formatMessage({ id: "food.page.title" })}</PageTitle>
      <Subtitle>
        <p>{formatMessage({ id: "food.page.subtitle" })}</p>
      </Subtitle>
      {guestsRequest.adults &&
        guestsRequest.adults.filter((guest) => guest.confirmedParty).length >
          0 && (
          <GuestsFood
            onChange={(guestName: string, foodOption: FoodOption) =>
              onFoodChange("adults", guestName, foodOption)
            }
            title={formatMessage({ id: "confirmation.page.adults" })}
            guests={guestsRequest.adults}
          />
        )}
      {guestsRequest.kids &&
        guestsRequest.kids.filter((guest) => guest.confirmedParty).length >
          0 && (
          <GuestsFood
            onChange={(guestName: string, foodOption: FoodOption) =>
              onFoodChange("kids", guestName, foodOption)
            }
            title={formatMessage({ id: "confirmation.page.kids" })}
            guests={guestsRequest.kids}
          />
        )}
      {guestsRequest.babies &&
        guestsRequest.babies.filter((guest) => guest.confirmedParty).length >
          0 && (
          <GuestsFood
            onChange={(guestName: string, foodOption: FoodOption) =>
              onFoodChange("babies", guestName, foodOption)
            }
            title={formatMessage({ id: "confirmation.page.babies" })}
            guests={guestsRequest.babies}
          />
        )}
      <BottomContainer>
        <StyledButton
          text={formatMessage({ id: "global.next.button" })}
          onClick={() => swiper.slideNext()}
        />
      </BottomContainer>
    </CenterContainer>
  );
}
