import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../styles/constants";
import StyledLink from "../components/StyledLink";
import {
  CenterContainer,
  MainTitle,
  Olive1Container,
  Olive2Container,
  PageContainer,
} from "../styles/components";
import { ReactComponent as Asset2 } from "../assets/Asset2.svg";
import { ReactComponent as Asset27 } from "../assets/Asset27.svg";
import { ReactComponent as Logo } from "../assets/Logo.svg";

export default function Home() {
  const { formatMessage } = useIntl();
  return (
    <PageContainer>
      <CenterContainer>
        <Olive1Container>
          <Asset2 />
        </Olive1Container>
        <Olive2Container>
          <Asset27 />
        </Olive2Container>
        <MainTitle>
          <Logo />
        </MainTitle>
        <Date>{formatMessage({ id: "home.date" })}</Date>
        <Address>{formatMessage({ id: "home.address" })}</Address>
        <HowToArrive
          rel="noreferrer"
          target="_blank"
          href="https://www.google.com/maps/place/Villa+Le+Bolli+(Wedding+Venue)/@43.2737532,11.0589445,17z/data=!4m9!3m8!1s0x132a20b64c8ad99f:0xdc13dc545754e763!5m2!4m1!1i2!8m2!3d43.2737493!4d11.0615194!16s%2Fg%2F11b745v708?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
        >
          {formatMessage({ id: "home.how-to-arrive" })}
        </HowToArrive>
        <StyledLink route={"/confirmation"} messageId={"home.confirm.button"} />
      </CenterContainer>
    </PageContainer>
  );
}

const Address = styled.p`
  color: ${PRIMARY_COLOR};
  font-size: 16px;
  margin-bottom: 8px;
`;

const HowToArrive = styled.a`
  color: ${SECONDARY_COLOR};
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 32px;
  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

const Date = styled.h2`
  color: ${SECONDARY_COLOR};
  font-size: 32px;
  font-weight: 100;
`;
