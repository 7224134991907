import React from "react";
import { useIntl } from "react-intl";
import {
  BottomContainer,
  Subtitle,
  CenterContainer,
  PageContainer,
  PageTitle,
} from "../../styles/components";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/constants";
import { useGuestsContext } from "../../context";
import StyledButton from "../StyledButton";

export default function MusicSlide() {
  const { formatMessage } = useIntl();
  const { guestsRequest, setGuestsRequest } = useGuestsContext();
  let navigate = useNavigate();
  const onMusicChange = (music: string) => {
    const newGuestsInfo = { ...guestsRequest };
    newGuestsInfo.music = music;
    setGuestsRequest(newGuestsInfo);
  };
  const [error, setError] = React.useState<string | undefined>(undefined);

  const onClick = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      await fetch(
        "https://administration.seel-schreibwaren.de:490/api/v1/guest",
        {
          method: "POST",
          body: JSON.stringify(guestsRequest),
          headers: myHeaders,
        }
      );
      navigate("/coming");
    } catch (e) {
      setError("music.page.error");
    }
  };

  return (
    <PageContainer>
      <CenterContainer>
        <PageTitle>{formatMessage({ id: "music.page.title" })}</PageTitle>
        <Subtitle>
          {formatMessage({ id: "music.page.subtitle" })}
          {error && <p>{formatMessage({ id: "music.page.error" })}</p>}
        </Subtitle>
        <StyledTextarea
          onChange={(e) => onMusicChange(e.currentTarget.value)}
        />
        <BottomContainer>
          <CenterContainer>
            <StyledButton
              onClick={onClick}
              text={formatMessage({ id: "global.next.finish" })}
            />
          </CenterContainer>
        </BottomContainer>
      </CenterContainer>
    </PageContainer>
  );
}

const StyledTextarea = styled.textarea`
  color: ${PRIMARY_COLOR};
  background-color: transparent;
  border: 2px solid ${PRIMARY_COLOR};
  padding: 8px 16px;
  margin-top: 24px;
  text-align: left;
  border-bottom: 1px solid ${PRIMARY_COLOR};
  outline: none;
`;
