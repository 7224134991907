import React from "react";
import StyledButton from "../StyledButton";
import { useIntl } from "react-intl";
import GuestsConfirmation from "../GuestsConfirmation";
import { useSwiper } from "swiper/react";
import {
  BottomContainer,
  BottomContainerCentered,
  CenterContainer,
  PageTitle,
  Subtitle,
} from "../../styles/components";
import { useGuestsContext } from "../../context";
import StyledLink from "../StyledLink";

export default function ConfirmationSlide() {
  const { guestsRequest, setGuestsRequest } = useGuestsContext();
  const { formatMessage } = useIntl();
  const swiper = useSwiper();

  const noGuest =
    guestsRequest.adults.filter((guest) => guest.confirmedParty).length < 1 &&
    (!guestsRequest.kids ||
      guestsRequest.kids?.filter((guest) => guest.confirmedParty).length < 1) &&
    (!guestsRequest.babies ||
      guestsRequest.babies?.filter((guest) => guest.confirmedParty).length < 1);

  const onCheckedChange = (
    key: "adults" | "kids" | "babies",
    guestName: string,
    checked: boolean
  ) => {
    const newGuestsInfo = { ...guestsRequest };
    let newGuestsInfoList = newGuestsInfo[key];
    if (newGuestsInfoList !== undefined) {
      newGuestsInfoList = newGuestsInfoList.map((guest) => {
        if (guest.name === guestName) {
          guest.confirmedParty = checked;
        }

        return guest;
      });
    }
    console.log(newGuestsInfo);
    setGuestsRequest(newGuestsInfo);
  };

  async function sendNotComing() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    await fetch(
      "https://administration.seel-schreibwaren.de:490/api/v1/guest",
      {
        method: "POST",
        body: JSON.stringify(guestsRequest),
        headers: myHeaders,
      }
    );
  }

  return (
    <CenterContainer>
      <PageTitle>{formatMessage({ id: "confirmation.page.title" })}</PageTitle>
      <Subtitle>
        <p>{formatMessage({ id: "confirmation.page.subtitle" })}</p>
      </Subtitle>
      <GuestsConfirmation
        onChange={(guestName: string, checked: boolean) =>
          onCheckedChange("adults", guestName, checked)
        }
        title={formatMessage({ id: "confirmation.page.adults" })}
        guests={guestsRequest.adults}
      />
      {guestsRequest.kids && guestsRequest.kids.length > 0 && (
        <GuestsConfirmation
          onChange={(guestName: string, checked: boolean) =>
            onCheckedChange("kids", guestName, checked)
          }
          title={formatMessage({ id: "confirmation.page.kids" })}
          guests={guestsRequest.kids}
        />
      )}
      {guestsRequest.babies && guestsRequest.babies.length > 0 && (
        <GuestsConfirmation
          onChange={(guestName: string, checked: boolean) =>
            onCheckedChange("babies", guestName, checked)
          }
          title={formatMessage({ id: "confirmation.page.babies" })}
          guests={guestsRequest.babies}
        />
      )}
      {noGuest ? (
        <BottomContainerCentered>
          <StyledLink
            onClick={sendNotComing}
            route={"/not-coming"}
            messageId={"confirmation.page.not-coming"}
          />
        </BottomContainerCentered>
      ) : (
        <BottomContainer>
          <StyledButton
            text={formatMessage({ id: "global.next.button" })}
            onClick={() => swiper.slideNext()}
          />
        </BottomContainer>
      )}
    </CenterContainer>
  );
}
