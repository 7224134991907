import React from "react";
import StyledButton from "../StyledButton";
import { useIntl } from "react-intl";
import { useSwiper } from "swiper/react";
import {
  BottomContainer,
  CenterContainer,
  PageTitle,
  Subtitle,
} from "../../styles/components";
import { useGuestsContext } from "../../context";
import PinInput from "react-pin-input";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../styles/constants";
import { GuestRequest } from "../../types";

export default function PinSlide() {
  const { setGuestsRequest } = useGuestsContext();
  const { formatMessage } = useIntl();
  const [pin, setPin] = React.useState<undefined | number>();
  const [error, setError] = React.useState<string | undefined>(undefined);

  const swiper = useSwiper();

  const onSubmit = async () => {
    if (pin && String(pin).length === 6) {
      console.log("set data");
      try {
        const response = await fetch(
          `https://administration.seel-schreibwaren.de:490/api/v1/guest?pin=${pin}`
        );
        const data = await response.json();
        // @ts-ignore
        if (data && data.adults) {
          setGuestsRequest({ ...data, pin } as unknown as GuestRequest);
          swiper.slideNext();
        } else {
          setError("pin.page.error");
        }
      } catch (e) {
        setError("pin.page.error");
      }
    }
  };

  return (
    <CenterContainer>
      <PageTitle>{formatMessage({ id: "pin.page.title" })}</PageTitle>
      <Subtitle>
        <p>{formatMessage({ id: "pin.page.subtitle" })}</p>
        {error && <p>{formatMessage({ id: "pin.page.error" })}</p>}
      </Subtitle>
      <PinInput
        length={6}
        onChange={(value, index) => {
          setPin(Number(value));
        }}
        type="numeric"
        inputMode="number"
        style={{ padding: "16px" }}
        inputStyle={{
          fontSize: "32px",
          margin: "8px",
          padding: "8px",
          borderColor: PRIMARY_COLOR,
        }}
        inputFocusStyle={{
          fontSize: "32px",
          margin: "8px",
          padding: "8px",
          borderColor: SECONDARY_COLOR,
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
      <BottomContainer>
        <StyledButton
          text={formatMessage({ id: "global.next.button" })}
          onClick={() => onSubmit()}
        />
      </BottomContainer>
    </CenterContainer>
  );
}
