import { createGlobalStyle } from "styled-components";
import { BACKGROUND_COLOR } from "./styles/constants";

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    background: ${BACKGROUND_COLOR};
    color: rgb(137,142,122)
    font-family: "RoxieRossa", sans-serif;
  }

  .pincode-input-text {
    @media screen and (max-width: 600px) {
    width: 24px !important;
    height: 48px !important;
    margin: 4px !important ;
  }
  }

  .pincode-input-container {
    @media screen and (max-width: 600px) {
      padding: 8px !important;
    }
  }
`;

export default GlobalStyle;
