import React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../styles/constants";
import { Link } from "react-router";
import { useIntl } from "react-intl";

type Props = {
  route: string;
  messageId: string;
  onClick?: () => void;
};

export default function StyledLink({ onClick, route, messageId }: Props) {
  const { formatMessage } = useIntl();

  return (
    <LinkStyled onClick={onClick} to={route}>
      <LinkContainer>{formatMessage({ id: messageId })}</LinkContainer>
    </LinkStyled>
  );
}

const LinkContainer = styled.div`
  padding: 8px 16px;
  color: ${PRIMARY_COLOR};
  border: 1px solid ${PRIMARY_COLOR};
  width: fit-content;

  &:hover {
    color: white;
    background-color: ${PRIMARY_COLOR};
  }
`;

const LinkStyled = styled(Link)`
  max-width: 148px;
  text-decoration: none;
  margin: 16px auto;
  position: relative;
  font-size: 24px;
  transition: 0.3s;
`;
